.Home_premiunMain__GBZMZ {
    background-image: url(./assets/bgmb.156ba569.png);
    width: 100%;
    display: flex;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
    .Home_premiunMain__GBZMZ {
        width: 100%;
        display: flex;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(./assets/bgmb.156ba569.png);
    }
}

.Home_container__9OuOz {
    padding: 30px 2rem;
    width: 100%;
    align-items: center;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .Home_container__9OuOz {
        padding: 30px 20px;
    }
}

.Home_header__ZUWxe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
}

.Home_navbar__MyWFx {
    display: flex;
    justify-content: flex-start;
    gap: 73px;
    align-items: center;
    text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
    .Home_logo__ZEOng {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .Home_actionBtnSelect__TpABK,
    .Home_logo__ZEOng {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .Home_logomb__9FMuz {
        display: block;
    }
}

@media only screen and (max-width: 900px) {
    .Home_logomb__9FMuz {
        display: block;
    }
}

@media only screen and (max-width: 900px) {
    .Home_logombTwo__IEMzM {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 9px;
    }
}

@media only screen and (max-width: 900px) {
    .Home_logombIcon__wCAJA {
        display: block;
        cursor: pointer;
    }
}

.Home_activeTab__P7pXh {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    padding-bottom: 8px;
}

.Home_activeTab__P7pXh,
.Home_tab__4XWq1:hover {
    color: #00ffd4;
    border-bottom: 2px solid #00ffd4;
}

.Home_tab__4XWq1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: #cdd6d4;
    padding-bottom: 8px;
}

.Home_nav__ZMqH2 {
    display: block;
    display: flex;
    align-items: center;
    gap: 50px;
}

@media only screen and (max-width: 768px) {
    .Home_nav__ZMqH2 {
        display: none;
    }
}

.Home_actionBtn__DBP4e {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.Home_actionBtnConnet__TXGJy {
    border-radius: 4px;
    background: radial-gradient(2058.68% 50% at 50% 50%);
}

.Home_buttons__L_amR {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.Home_highlight__TZ0SE {
    position: relative;
}

.Home_actionBtnSelectPackagebuy__FXscv,
.Home_actionBtnSelect__TpABK {
    display: flex;
    gap: 10px;
    background: radial-gradient(
            2058.68% 50% at 50% 50%,
            rgba(43, 147, 122, 0.1) 0,
            rgba(43, 147, 122, 0) 100%
        ),
        #0a1013;
}

.Home_actionBtnSelect__TpABK {
    border-radius: 4px;
    border: 1px solid #2b937a;
    color: #fff;
}

.css-5ql82x:not([data-inverted-colors="false"]) {
    --Select-focusedHighlight: var(
        --joy-palette-focusVisible,
        var(--joy-palette-primary-500, #0b6bcb)
    );
}

.Home_actionBtnSelect__TpABK > div {
    background: none !important;
}

.css-5ql82x {
    --Select-radius: var(--joy-radius-sm, 6px);
    --Select-gap: 0.5rem;
    --Select-placeholderOpacity: 0.64;
    --Select-decoratorColor: var(
        --joy-palette-text-icon,
        var(--joy-palette-neutral-500, #636b74)
    );
    --Select-focusedThickness: var(--joy-focus-thickness, 2px);
    --Select-focusedHighlight: var(--joy-palette-primary-500, #0b6bcb);
    --Select-indicatorColor: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    --Select-minHeight: 2.25rem;
    --Select-paddingInline: 0.75rem;
    --Select-decoratorChildHeight: min(1.75rem, var(--Select-minHeight));
    --Icon-fontSize: var(--joy-fontSize-xl2, 1.5rem);
    --Select-decoratorChildOffset: min(
        calc(
            var(--Select-paddingInline) -
                (
                    var(--Select-minHeight) - 2 *
                        var(--variant-borderWidth, 0px) -
                        var(--Select-decoratorChildHeight)
                ) / 2
        ),
        var(--Select-paddingInline)
    );
    --_Select-paddingBlock: max(
        (
                var(--Select-minHeight) - 2 * var(--variant-borderWidth, 0px) -
                    var(--Select-decoratorChildHeight)
            ) / 2,
        0px
    );
    --Select-decoratorChildRadius: max(
        var(--Select-radius) - var(--variant-borderWidth, 0px) -
            var(--_Select-paddingBlock),
        min(
            var(--_Select-paddingBlock) + var(--variant-borderWidth, 0px),
            var(--Select-radius) / 2
        )
    );
    --Button-minHeight: var(--Select-decoratorChildHeight);
    --Button-paddingBlock: 0px;
    --IconButton-size: var(--Select-decoratorChildHeight);
    --Button-radius: var(--Select-decoratorChildRadius);
    --IconButton-radius: var(--Select-decoratorChildRadius);
    box-sizing: border-box;
    box-shadow: var(--joy-shadowRing, 0 0 #000),
        0px 1px 2px 0px
            rgba(
                var(--joy-shadowChannel, 21 21 21) /
                    var(--joy-shadowOpacity, 0.08)
            );
    min-height: var(--Select-minHeight);
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: var(--Select-radius);
    cursor: pointer;
    padding-block: 3px;
    padding-inline: var(--Select-paddingInline);
    font-family: var(
        --joy-fontFamily-body,
        "Inter",
        var(
            --joy-fontFamily-fallback,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Helvetica,
            Arial,
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol"
        )
    );
    font-size: var(--joy-fontSize-md, 1rem);
    line-height: var(--joy-lineHeight-md, 1.5);
    color: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    --variant-borderWidth: 0px;
    background-color: var(
        --variant-solidBg,
        var(
            --joy-palette-neutral-solidBg,
            var(--joy-palette-neutral-500, #636b74)
        )
    );
    --ListItemDecorator-size: 44px;
    min-width: 145px;
}

.CustomSelect_selectLangue__De7sO {
    display: flex;
    gap: 10px;
    background: radial-gradient(
            2058.68% 50% at 50% 50%,
            rgba(43, 147, 122, 0.1) 0,
            rgba(43, 147, 122, 0) 100%
        ),
        #0a1013;
    color: #fff;
}

.css-1qmzz5g {
    border: 0px;
    outline: 0px;
    background: none;
    padding: 0px;
    font-size: inherit;
    color: inherit;
    align-self: stretch;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    font-family: inherit;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}

.css-1une5eo {
    box-sizing: border-box;
    display: inline-flex;
    -webkit-box-align: var(--unstable_ListItemDecorator-alignItems, center);
    align-items: var(--unstable_ListItemDecorator-alignItems, center);
    min-inline-size: var(--ListItemDecorator-size);
    margin-inline-end: calc(-1 * var(--ListItem-gap));
}

.css-1tnykim {
    --Icon-fontSize: var(--joy-fontSize-xl, 1.25rem);
    --Icon-color: currentColor;
    display: inherit;
    -webkit-box-align: center;
    align-items: center;
    margin-inline-start: var(--Select-gap);
    margin-inline-end: calc(var(--Select-paddingInline) / -4);
}

.css-vubbuv {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {
    .Home_actionBtnSelect__TpABK,
    .Home_logo__ZEOng {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .Home_actionBtnSelect__TpABK,
    .Home_logo__ZEOng {
        display: none;
    }
}

.Home_homeContentSubmitSelect__7PqzH {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .Home_homeContentSubmitSelect__7PqzH {
        display: block;
        margin-bottom: 16px;
    }
}

.Home_actionBtnSelectPackagebuy__FXscv {
    border-radius: 4px 0 0 4px;
    border: 1px solid #2b937a;
    height: 60px;
}

@media only screen and (max-width: 768px) {
    .Home_actionBtnSelectPackagebuy__FXscv {
        height: 52px;
        height: 52px;
        width: fit-content;
        justify-content: center;
        display: flex;
        margin-inline: auto;
    }
}

.Home_actionBtnSelectPackagebuy__FXscv > div {
    background: none !important;
    color: #fff;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.css-1heoa4c {
    --Select-radius: var(--joy-radius-sm, 6px);
    --Select-gap: 0.5rem;
    --Select-placeholderOpacity: 0.64;
    --Select-decoratorColor: var(
        --joy-palette-text-icon,
        var(--joy-palette-neutral-500, #636b74)
    );
    --Select-focusedThickness: var(--joy-focus-thickness, 2px);
    --Select-focusedHighlight: var(--joy-palette-primary-500, #0b6bcb);
    --Select-indicatorColor: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    --Select-minHeight: 2.25rem;
    --Select-paddingInline: 0.75rem;
    --Select-decoratorChildHeight: min(1.75rem, var(--Select-minHeight));
    --Icon-fontSize: var(--joy-fontSize-xl2, 1.5rem);
    --Select-decoratorChildOffset: min(
        calc(
            var(--Select-paddingInline) -
                (
                    var(--Select-minHeight) - 2 *
                        var(--variant-borderWidth, 0px) -
                        var(--Select-decoratorChildHeight)
                ) / 2
        ),
        var(--Select-paddingInline)
    );
    --_Select-paddingBlock: max(
        (
                var(--Select-minHeight) - 2 * var(--variant-borderWidth, 0px) -
                    var(--Select-decoratorChildHeight)
            ) / 2,
        0px
    );
    --Select-decoratorChildRadius: max(
        var(--Select-radius) - var(--variant-borderWidth, 0px) -
            var(--_Select-paddingBlock),
        min(
            var(--_Select-paddingBlock) + var(--variant-borderWidth, 0px),
            var(--Select-radius) / 2
        )
    );
    --Button-minHeight: var(--Select-decoratorChildHeight);
    --Button-paddingBlock: 0px;
    --IconButton-size: var(--Select-decoratorChildHeight);
    --Button-radius: var(--Select-decoratorChildRadius);
    --IconButton-radius: var(--Select-decoratorChildRadius);
    box-sizing: border-box;
    box-shadow: var(--joy-shadowRing, 0 0 #000),
        0px 1px 2px 0px
            rgba(
                var(--joy-shadowChannel, 21 21 21) /
                    var(--joy-shadowOpacity, 0.08)
            );
    min-width: 0px;
    min-height: var(--Select-minHeight);
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: var(--Select-radius);
    cursor: pointer;
    padding-block: 3px;
    padding-inline: var(--Select-paddingInline);
    font-family: var(
        --joy-fontFamily-body,
        "Inter",
        var(
            --joy-fontFamily-fallback,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Helvetica,
            Arial,
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol"
        )
    );
    font-size: var(--joy-fontSize-md, 1rem);
    line-height: var(--joy-lineHeight-md, 1.5);
    color: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    --variant-borderWidth: 0px;
    background-color: var(
        --variant-solidBg,
        var(
            --joy-palette-neutral-solidBg,
            var(--joy-palette-neutral-500, #636b74)
        )
    );
}

.css-1heoa4c:not([data-inverted-colors="false"]) {
    --Select-focusedHighlight: var(
        --joy-palette-focusVisible,
        var(--joy-palette-primary-500, #0b6bcb)
    );
}

.CustomSelect_selectPackageOptionLable__Ur2DX {
    display: flex;
    /* padding: 25px 15px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .CustomSelect_selectPackageOptionLable__Ur2DX {
        font-size: 20px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
}

.css-1tnykim {
    --Icon-fontSize: var(--joy-fontSize-xl, 1.25rem);
    --Icon-color: currentColor;
    display: inherit;
    -webkit-box-align: center;
    align-items: center;
    margin-inline-start: var(--Select-gap);
    margin-inline-end: calc(var(--Select-paddingInline) / -4);
}

.css-vubbuv {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

.Home_homeContentSubmit__V2r1x {
    display: block;
    width: 588px;
    height: 60px;
    border-radius: 0 8px 8px 0;
    background: hsla(0, 0%, 100%, 0.1);
    padding-left: 0px;
    padding-bottom: 0px;
}

@media only screen and (max-width: 768px) {
    .Home_homeContentSubmit__V2r1x {
        display: none;
        padding: 0px;
    }
}

.Home_homeContentSubmitContainer____0ax {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Home_homeContentSubmitInput__uzhRP {
    width: 100%;
    height: 100%;
    background: none;
    outline: none;
    border: none;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.Home_homeContentSubmitBtn__S8u5P {
    border: none;
    text-wrap: nowrap;
    margin: 2px;
    border-radius: 4px;
    background: #2b937a;
    padding: 15px 18px;
    color: #f0fffb;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    cursor: pointer;
}

.Home_homeContentSubmitInputMb__RSkh_ {
    border-radius: 8px;
    background: hsla(0, 0%, 100%, 0.1);
    padding: 0;
    color: #fff;
    width: 100%;
    border: none;
    height: 40px;
    outline: none;
}

.Home_homeContentSubmitMb___IIYH {
    display: none;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .Home_homeContentSubmitMb___IIYH {
        display: block;
    }
}

.Home_homeContentTextEnd__SGVob {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    background: linear-gradient(180deg, #e2fff7, #28ffc6);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    padding: 51px 0 52px;
}

.Home_homeFooter__4vo5d {
    border-top: 1px dashed hsla(0, 0%, 100%, 0.1);
    text-align: center;
    padding: 27px 0 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #9aa7a4;
}

.Sidebar_sidebar__oTTpu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #0c0f11;
    padding-top: 20px;
    transition: left 0.3s ease;
}

.Sidebar_sidebarContainer__DxHFn {
    padding: 22px 16px;
}

.Sidebar_sidebar_btn__cOnIo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}

.Sidebar_sidebarUl__KMePg {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.Sidebar_sidebarli__bTR8P {
    padding: 18px 0;
}

.Sidebar_sidebara__BI2VQ {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

.css-o9b5ht {
    --Icon-margin: initial;
    --Icon-color: currentColor;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    text-align: initial;
    background-color: var(
        --variant-solidBg,
        var(
            --joy-palette-neutral-solidBg,
            var(--joy-palette-neutral-500, #636b74)
        )
    );
    cursor: pointer;
    margin-block-start: var(--List-gap);
    padding-inline-start: calc(
        var(--ListItem-paddingLeft) +
            var(
                --ListItem-startActionWidth,
                var(--unstable_startActionWidth, 0px)
            )
    );
    padding-inline-end: calc(
        var(--ListItem-paddingRight) +
            var(--ListItem-endActionWidth, var(--unstable_endActionWidth, 0px))
    );
    min-block-size: var(--ListItem-minHeight);
    min-inline-size: 0px;
    --variant-borderWidth: 0px;
    color: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    font: inherit;
    gap: var(--ListItem-gap);
    text-decoration: initial;
    margin-inline: var(--ListItemButton-marginInline);
    padding-block: calc(
        var(--ListItem-paddingY) - var(--variant-borderWidth, 0px)
    );
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: var(--ListItem-radius);
    flex: var(--unstable_ListItem-flex, none);
}

.MuiSelect-listbox {
    position: absolute;
    top: 60;
    display: block;
    width: 100%;
    padding: 10px;
    margin-inline-start: -12px;
}

.CustomSelect_selectPackageInputOption__bowNO {
    border: 1px solid #fff;
    background: none;
    border: none;
}

.CustomSelect_selectPackageInputOption__bowNO:hover {
    background-color: grey;
}

.css-12tu8y4 {
    --ListDivider-gap: 0.375rem;
    --ListItem-minHeight: 2.25rem;
    --ListItem-paddingY: 0.25rem;
    --ListItem-paddingX: 0.75rem;
    --ListItem-gap: 0.625rem;
    --ListItemDecorator-size: 2.5rem;
    --Icon-fontSize: var(--joy-fontSize-xl, 1.25rem);
    --List-gap: 0px;
    --List-nestedInsetStart: 0px;
    --ListItem-paddingLeft: var(--ListItem-paddingX);
    --ListItem-paddingRight: var(--ListItem-paddingX);
    --unstable_List-childRadius: calc(
        max(
                var(--List-radius) - var(--List-padding),
                min(var(--List-padding) / 2, var(--List-radius) / 2)
            ) - var(--variant-borderWidth, 0px)
    );
    --ListItem-radius: var(--unstable_List-childRadius);
    --ListItem-startActionTranslateX: calc(0.5 * var(--ListItem-paddingLeft));
    --ListItem-endActionTranslateX: calc(-0.5 * var(--ListItem-paddingRight));
    margin: initial;
    font-family: var(
        --joy-fontFamily-body,
        "Inter",
        var(
            --joy-fontFamily-fallback,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            Helvetica,
            Arial,
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol"
        )
    );
    font-size: var(--joy-fontSize-md, 1rem);
    line-height: var(--joy-lineHeight-md, 1.5);
    padding-block: var(--List-padding, var(--ListDivider-gap));
    padding-inline: var(--List-padding);
    box-sizing: border-box;
    list-style: none;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
    --variant-borderWidth: 0px;
    color: var(
        --variant-solidColor,
        var(
            --joy-palette-neutral-solidColor,
            var(--joy-palette-common-white, #fff)
        )
    );
    background-color: var(
        --variant-solidBg,
        var(
            --joy-palette-neutral-solidBg,
            var(--joy-palette-neutral-500, #636b74)
        )
    );
    --unstable_List-borderWidth: var(--variant-borderWidth, 0px);
    --focus-outline-offset: calc(var(--joy-focus-thickness, 2px) * -1);
    --ListItem-stickyBackground: var(
        --variant-solidBg,
        var(
            --joy-palette-neutral-solidBg,
            var(--joy-palette-neutral-500, #636b74)
        )
    );
    --ListItem-stickyTop: calc(
        var(--List-padding, var(--ListDivider-gap)) * -1
    );
    --NestedList-marginRight: 0px;
    --NestedList-marginLeft: 0px;
    --NestedListItem-paddingLeft: var(--ListItem-paddingX);
    --ListItemButton-marginBlock: 0px;
    --ListItemButton-marginInline: 0px;
    --ListItem-marginBlock: 0px;
    --ListItem-marginInline: 0px;
    min-width: max-content;
    max-height: 44vh;
    overflow: auto;
    outline: 0px;
    box-shadow: var(--joy-shadowRing, 0 0 #000),
        0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) /
                    var(--joy-shadowOpacity, 0.08)),
        0px 6px 12px -2px rgba(var(--joy-shadowChannel, 21 21 21) /
                    var(--joy-shadowOpacity, 0.08));
    border-radius: var(--List-radius, var(--joy-radius-sm, 6px));
    z-index: var(--unstable_popup-zIndex, var(--joy-zIndex-popup, 1000));
}
